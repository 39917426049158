// Pagination.js
import React from 'react';
import './css/paging.css'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    let startPage = currentPage >= 5 ? currentPage - 2 : 1;
    let endPage = currentPage + 2 <= totalPages ? currentPage + 2 : totalPages;

    // Ensure there are always 3 pages displayed if possible
    if (currentPage < 5) {
      endPage = 5;
    } else if (currentPage > totalPages - 4) {
      startPage = totalPages - 4;
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <button onClick={() => onPageChange(1)}>1</button>
            <span>...</span>
          </>
        )}
        {pageNumbers.slice(startPage - 1, endPage).map(number => (
          <button
            key={number}
            onClick={() => onPageChange(number)}
            className={number === currentPage ? 'active' : ''}
          >
            {number}
          </button>
        ))}
        {endPage < totalPages && (
          <>
            <span>...</span>
            <button onClick={() => onPageChange(totalPages)}>{totalPages}</button>
          </>
        )}
      </>
    );
  };

  return (
    <div className="pagination">
      <button 
        onClick={() => onPageChange(currentPage - 1)} 
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      {renderPageNumbers()}
      <button 
        onClick={() => onPageChange(currentPage + 1)} 
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;
