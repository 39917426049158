import React from 'react';

const SvgIcons = () => (
  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
    <symbol id="svg-home-bar" viewBox="0 0 24 24">
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
    </symbol>
    <symbol id="svg-search" viewBox="0 0 24 24">
      <path d="M23 21l-6.35-6.35A9 9 0 1016 18a9 9 0 005.65-2.35L23 21zM10 18A8 8 0 1110 2a8 8 0 010 16z"></path>
    </symbol>
    <symbol id="svg-menu" viewBox="0 0 24 24">
      <path d="M3 12h18v2H3v-2zm0-7h18v2H3V5zm0 14h18v2H3v-2z"></path>
    </symbol>
    <symbol id="svg-support" viewBox="0 0 24 24">
      <path d="M12 2a10 10 0 00-3.16 19.47L8 22a1 1 0 01-.54-1.84A10 10 0 1012 2zm1 17h-2v-2h2zm1.07-7.75L12 14v2h-2v-3l2.54-1.54A2 2 0 0010 8h-2a4 4 0 118 0c0 1.08-.43 2.05-1.07 2.75z"></path>
    </symbol>
    <symbol id="svg-shopping-cart" viewBox="0 0 24 24">
      <path d="M7 18c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-2h13.12l1.38-6H8.24l-.59-3H2V5h5.24l1.31 7H20v2H7zm10 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
    </symbol>
  </svg>
);

export default SvgIcons;