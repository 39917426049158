import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from 'axios';
import Modal from './Modal.js';
import Pagination from './Pagination.js';
import ModalDichbien from './ModalDichbien.js';

import 'flickity/css/flickity.css';

import './css/flatpickr.min.css';
import './css/flatsome.css';
import './css/style-text.css';
import './css/styles.css';

import banner1 from './image/banner1.png';
import banner2 from './image/banner2.png';
import banner3 from './image/banner3.png';
import banner4 from './image/banner2.png';
import flogo1 from './image/flogo1.png';
import flogo3 from './image/flogo3.png';
import i1 from './image/i1.svg';
import i2 from './image/i2.svg';
import i3 from './image/i3.svg';
import logoNew from './image/logo-new.jpg';
import logoSaleNoti from './image/logoSaleNoti.png';

// Contants
import { API_BASE_URL_LOCAL, API_BASE_URL_SERVER } from './contants.js';


function Admin() {
  const [activeFilter, setActiveFilter] = useState('qh_dshc');
  const [contentData, setContentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);
  const [bks, setBks] = useState('');
  const [index, setIndex] = useState(1);
  const [bksSearch, setBksSearch] = useState('');
  const [price, setPrice] = useState('');
  const handleClose = () => setShow(false);
  const [maLoaiXe, setMaLoaiXe] = useState('');
  const [maTinh, setMaTinh] = useState('');
  const [sortBy, setSortBy] = useState('gia_dau_gia');
  const [phone, setPhone] = useState('');
  const [toEmail, setToEmail] = useState('');
  const [note, setNote] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isModalDichOpen, setIsModalDichOpen] = useState(false);
  const [dateBsdg, setDateBsdg] = useState('');
  const [typeBks, setTypeBks] = useState('');
  const [id, setId] = useState(null);

  useEffect(() => {
    handleFilterClick('qh_dshc');
  }, []);

  const handlePageChange = (pageNumber) => {
    setIndex((pageNumber * 30) - 29);
    setCurrentPage(pageNumber);
  };

  const handleChangeBksSearch = (event) => {
    setBksSearch(event.target.value);
  };

  const handleChangePrice = (event) => {
    setPrice(event.target.value);
  };

  const handlePhone = (event) => {
    console.log(event.target.value)
    setPhone(event.target.value);
  };

  const handleNote = (event) => {
    setNote(event.target.value);
  };

  const handleToEmail = (event) => {
    setToEmail(event.target.value);
  };

  const handleChangeMaLoaiXe = (event) => {
    const value = event.target.value;
    setMaLoaiXe(value);
  };

  const handleChangeMaTinh = (event) => {
    const value = event.target.value;
    setMaTinh(value);
  };

  const handleChangeTypeBks = (event) => {
    const value = event.target.value;
    setTypeBks(value);
  };

    // -------------- //
    const provinces = [
      { name: "Tỉnh Thành", matinh: "" },
      { name: "Hà Nội", matinh: "01" },
      { name: "Hà Giang", matinh: "02" },
      { name: "Cao Bằng", matinh: "04" },
      { name: "Bắc Kạn", matinh: "06" },
      { name: "Tuyên Quang", matinh: "08" },
      { name: "Lào Cai", matinh: "10" },
      { name: "Điện Biên", matinh: "11" },
      { name: "Lai Châu", matinh: "12" },
      { name: "Sơn La", matinh: "14" },
      { name: "Yên Bái", matinh: "15" },
      { name: "Hòa Bình", matinh: "17" },
      { name: "Thái Nguyên", matinh: "19" },
      { name: "Lạng Sơn", matinh: "20" },
      { name: "Quảng Ninh", matinh: "22" },
      { name: "Bắc Giang", matinh: "24" },
      { name: "Phú Thọ", matinh: "25" },
      { name: "Vĩnh Phúc", matinh: "26" },
      { name: "Bắc Ninh", matinh: "27" },
      { name: "Hải Dương", matinh: "30" },
      { name: "Hải Phòng", matinh: "31" },
      { name: "Hưng Yên", matinh: "33" },
      { name: "Thái Bình", matinh: "34" },
      { name: "Hà Nam", matinh: "35" },
      { name: "Nam Định", matinh: "36" },
      { name: "Ninh Bình", matinh: "37" },
      { name: "Thanh Hóa", matinh: "38" },
      { name: "Nghệ An", matinh: "40" },
      { name: "Hà Tĩnh", matinh: "42" },
      { name: "Quảng Bình", matinh: "44" },
      { name: "Quảng Trị", matinh: "45" },
      { name: "Thừa Thiên Huế", matinh: "46" },
      { name: "Đà Nẵng", matinh: "48" },
      { name: "Quảng Nam", matinh: "49" },
      { name: "Quảng Ngãi", matinh: "51" },
      { name: "Bình Định", matinh: "52" },
      { name: "Phú Yên", matinh: "54" },
      { name: "Khánh Hòa", matinh: "56" },
      { name: "Ninh Thuận", matinh: "58" },
      { name: "Bình Thuận", matinh: "60" },
      { name: "Kon Tum", matinh: "62" },
      { name: "Gia Lai", matinh: "64" },
      { name: "Đắk Lắk", matinh: "66" },
      { name: "Đắk Nông", matinh: "67" },
      { name: "Lâm Đồng", matinh: "68" },
      { name: "Bình Phước", matinh: "70" },
      { name: "Tây Ninh", matinh: "72" },
      { name: "Bình Dương", matinh: "74" },
      { name: "Đồng Nai", matinh: "75" },
      { name: "Bà Rịa - Vũng Tàu", matinh: "77" },
      { name: "Hồ Chí Minh", matinh: "79" },
      { name: "Long An", matinh: "80" },
      { name: "Tiền Giang", matinh: "82" },
      { name: "Bến Tre", matinh: "83" },
      { name: "Trà Vinh", matinh: "84" },
      { name: "Vĩnh Long", matinh: "86" },
      { name: "Đồng Tháp", matinh: "87" },
      { name: "An Giang", matinh: "89" },
      { name: "Kiên Giang", matinh: "91" },
      { name: "Cần Thơ", matinh: "92" },
      { name: "Hậu Giang", matinh: "93" },
      { name: "Sóc Trăng", matinh: "94" },
      { name: "Bạc Liêu", matinh: "95" },
      { name: "Cà Mau", matinh: "96" }
    ];
  
    const loaixe = [
      {
        name: "Xe con", maLoaiXe: "1"
      }, {
        name: "Xe tải van", maLoaiXe: "4"
      }, {
        name: "Xe tải", maLoaiXe: "3"
      }, {
        name: "Xe khách", maLoaiXe: "2"
      }
    ];
  
    const loaibien = [
      {
        name: "Ngũ quý", maLoaiBien: "2"
      }, {
        name: "Sảnh tiến", maLoaiBien: "9"
      }, {
        name: "Tứ quý", maLoaiBien: "3"
      }, {
        name: "Tam hoa", maLoaiBien: "4"
      }, {
        name: "Thần tài", maLoaiBien: "1"
      }, {
        name: "Lộc phát", maLoaiBien: "6"
      }, {
        name: "Ông địa", maLoaiBien: "7"
      }, {
        name: "Số gánh", maLoaiBien: "18"
      }, {
        name: "Lặp đôi", maLoaiBien: "10"
      }, {
        name: "Tam đầu hoả", maLoaiBien: "11"
      }, {
        name: "Biển phong thuỷ", maLoaiBien: "12"
      }, {
        name: "Biển dễ nhớ", maLoaiBien: "13"
      }, {
        name: "Biển đuôi 69 96", maLoaiBien: "14"
      }, {
        name: "Tài phát", maLoaiBien: "15"
      }
    ]

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    resetVariable();
    // if (activeFilter === 'qh_dshc') {
    //   callApiDscb();
    // } 
  };

  const resetVariable = () => {
    setBksSearch('');
    setMaTinh('');
    setMaLoaiXe('');
    setSortBy('');
    setFromDate('');
    setToDate('');
    setDateBsdg('');
    setPrice('');
    setTypeBks('');
    handlePageChange(1);
    setContentData([]);
    setCurrentPage(1);
    setTotalPages(1);
    setId(null);
  }

  useEffect(() => {
    if (activeFilter === 'qh_dshc') {
      callApiDscb();
    } 
  }, [currentPage, index, bksSearch, 
        maLoaiXe, maTinh, sortBy, toDate, 
        fromDate, dateBsdg, typeBks, activeFilter]);

  const callApiDscb = async () => {
    try {
      let size = 15;
      const params = new URLSearchParams({
        page: currentPage == null ? 1 : currentPage,
        size: size,
        bks: bksSearch,
        maTinh: maTinh,
        maLoaiXe: maLoaiXe,
        maLoaiBien: typeBks
      });
      // const response = await fetch(`https://vpa.com.vn/api/v1/bks/search?${params}`);
      const response = await fetch(`${API_BASE_URL_SERVER}bien-so/bsct/list?${params}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      parseVpaDataDscb(data.content);
      //paging data
      setTotalPages(Math.ceil(data.totalElements / size));
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  const parseVpaDataDscb = (vpaDataList) => {
    const data = [];
    {
      let stt = index;
      vpaDataList.map((item) => (
        data.push({
          "stt": stt,
          "id": item.id,
          "bks": item.bks,
          "gia_dau_gia": item.giaDauGia,
          "ten_tinh": item.maTinh,
          "loai_xe": item.maLoaiXe,
          "loai_bien": item.maLoaiBien
        }),
        stt = stt + 1
      ))
    }
    setContentData(data)
  }

  const callApiCreateAndUpdate = async (id) => {
    try {
      let body = id === null ? {
        bks : bksSearch,
        giaDauGia : price,
        maTinh : maTinh,
        maLoaiXe : maLoaiXe,
        maLoaiBien : typeBks
      } : 
      {
        id: id,
        bks : bksSearch,
        giaDauGia : price,
        maTinh : maTinh,
        maLoaiXe : maLoaiXe,
        maLoaiBien : typeBks
      };
      await axios.post(`${API_BASE_URL_SERVER}bien-so/bsct/create`, body);
      alert('Thêm biển thành công')
      setActiveFilter('qh_dshc')
      resetVariable();
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  // --------------- EMAIL
  const handleSendEmail = async (e) => {
    let subject = bks;

    if (activeFilter === 'qh_dshc') {
      subject = 'Đăng ký mua biển hiện có: ' + subject;
    } else if (activeFilter === 'qh_bnhc') {
      subject = 'Đăng ký mua biển bấm ngay sẵn có: ' + subject;
    } else if (activeFilter === 'qh_dsct') {
      subject = 'Đăng ký mua biển danh sách chính thức: ' + subject;
    } else if (activeFilter === 'qh_bsdg') {
      subject = 'Đăng ký mua biển sắp đấu giá: ' + subject;
    }

    let body = {
      subject: subject,
      body: 'Phone: ' + phone + '\nEmail: ' + toEmail + '\nGhi chú thêm: ' + note
    };

    try {
      await axios.post(`${API_BASE_URL_SERVER}send-email`, body);
    } catch (error) {
    }
    handleClose();
  };

  const handleDelete = async (id) => {
    if (window.confirm("Bạn có muốn xóa biển không?")) {
      try {
        await axios.delete(`${API_BASE_URL_SERVER}bien-so/bsct/delete?id=${id}`);
        callApiDscb()
      } catch (error) {
        // setError(error);
      } finally {
        // setLoading(false);
      }
    } else {
      // Cancel action
      console.log("Action canceled" + id);
    }
  };

  const handleDetail = async (id) => {
    try {
      setId(id)
      const response = await fetch(`${API_BASE_URL_SERVER}bien-so/bsct/detail?id=${id}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setActiveFilter('qh_bnhc')
      setBksSearch(data.bks)
      setPrice(data.giaDauGia)
      setMaTinh(data.maTinh)
      setMaLoaiXe(data.maLoaiXe)
      setTypeBks(data.maLoaiBien)
      //paging data
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,          // Enable auto-slide
    autoplaySpeed: 3000,     // Time in milliseconds (3 seconds)
    pauseOnHover: true,      // Pause auto-slide when hovering over the slider
  };

  const formatCurrency = (value) => {
    if (!value) return '';

    const amount = parseFloat(value);
    const formatter = new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    });

    return formatter.format(amount);
  };

  return (
    <div className="page-template page-template-page-blank page-template-page-blank-php page page-id-635 lightbox nav-dropdown-has-arrow nav-dropdown-has-shadow nav-dropdown-has-border">
      <div id="wrapper">
        <header className="header has-sticky sticky-jump">
          <div className="header-wrapper">
            <div id="masthead" className="header-main nav-dark">
              <div className="header-inner flex-row container logo-left medium-logo-center" role="navigation">
                {/* Logo */}
                <div id="logo" className="flex-col logo">
                  <a href="" title="Biển số đẹp" rel="home">
                    {/* <img width="857" height="1024" src={logoNew} className="header_logo header-logo" alt="Biển số đẹp" />
                    <img width="857" height="1024" src={logoNew} className="header-logo-dark" alt="Biển số đẹp" /> */}
                    <img
                      style={{ filter: 'none' }}
                      className="alignnone wp-image-2666"
                      src={logoNew}
                      alt=""
                      width="400"
                      height="80"
                    />
                  </a>
                </div>
                <div className="flex-col show-for-medium flex-right">
                  <ul className="mobile-nav nav nav-right"></ul>
                </div>
              </div>
              <div className="container">
                <div className="top-divider full-width"></div>
              </div>
            </div>
            <div className="header-bg-container fill">
              <div className="header-bg-image fill"></div>
              <div className="header-bg-color fill"></div>
            </div>
          </div>
        </header>

        <div className="slider-wrapper relative qh_slide" id="slider-1663031332">
          <div >
            <Slider style={{ backgroundColor: 'white' }} {...settings}>
              <div>
                <img src={banner1} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
              <div style={{ padding: "3px" }}>
                <img src={banner2} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
              <div style={{ padding: "3px" }}>
                <img src={banner3} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
              <div style={{ padding: "3px" }}>
                <img src={banner4} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
            </Slider>
          </div>
        </div>

        <main id="main" className="dark dark-page-wrapper">
          <div id="content" role="main" className="content-area">
            <section className="section dark" id="section_710753446">
              <div className="bg section-bg fill bg-fill bg-loaded back-ground-co"></div>
              <div className="section-content relative">
                <div class="row row-small row-full-width" id="row-652602724">
                  <div id="col-1854014999" className="col hide-for-medium medium-2 small-12 large-2">
                    <div className="col-inner">

                    </div>
                  </div>
                  <div id="col-637516760" className="col medium-12 small-12 large-8">
                    <div className="col-inner">
                      <div className="row row-small qh_widt">
                        <div className="col large-12">
                          <div className="col-inner">
                            {/* <h1 style={{ marginTop: '3%', marginBottom: '3%' }}>Biển số đẹp</h1> */}
                            <ul className="qh_ftb" style={{ marginBottom: '5%' }}>
                              <li
                                className={activeFilter === 'qh_dshc' ? 'active' : ''}
                                onClick={() => handleFilterClick('qh_dshc')}
                              >
                                <b>Danh sách biển số</b>
                              </li>
                              <li
                                className={activeFilter === 'qh_bnhc' ? 'active' : ''}
                                onClick={() => handleFilterClick('qh_bnhc')}
                              >
                                <b>Thêm / Sửa biển số</b>
                              </li>
                            </ul>
                            <div
                              id="bienxevip"
                              data-aff=".qh_dshc">

                              {activeFilter === 'qh_dshc' &&
                                <div className="qh_filter">
                                  <div className="a c1">
                                    <img decoding="async" src={i3} alt="input" />
                                    <input
                                      type="text"
                                      placeholder="Nhập tìm kiếm biển số xe"
                                      autoComplete="off"
                                      value={bksSearch}
                                      onChange={handleChangeBksSearch}
                                    />
                                  </div>
                                  <div className="b c2">
                                    <img decoding="async" src={i2} alt="select" />
                                    <div className="b1">
                                      <select className="dropdown"
                                        style={{
                                          marginBottom: '0',
                                          backgroundColor: 'transparent',
                                          border: 'none',
                                          color: 'white'
                                        }}
                                        value={maTinh}
                                        onChange={handleChangeMaTinh}
                                      >
                                        {provinces.map((province) => (
                                          <option style={{ color: 'black' }} value={province.matinh} >{province.name}</option >
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="b c3">
                                    <img decoding="async" src={i1} alt="select" />
                                    <div className="b1">
                                      <select className="dropdown"
                                        style={{
                                          marginBottom: '0',
                                          backgroundColor: 'transparent',
                                          border: 'none',
                                          color: 'white'
                                        }}
                                        onChange={handleChangeMaLoaiXe}
                                        value={maLoaiXe} >
                                        <option style={{ color: 'black' }} value="">Loại Xe</option>
                                        <option style={{ color: 'black' }} value="1">Xe con</option>
                                        <option style={{ color: 'black' }} value="4">Xe tải van</option>
                                        <option style={{ color: 'black' }} value="3">Xe tải</option>
                                        <option style={{ color: 'black' }} value="2">Xe khách</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="b c5">
                                    <img decoding="async" src={i1} alt="select" />
                                    <select className="dropdown"
                                      style={{
                                        marginBottom: '0',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        color: 'white'
                                      }}
                                      onChange={handleChangeTypeBks}
                                      value={typeBks} >
                                      <option style={{ color: 'black' }} value="">Chọn loại biển</option>
                                      {loaibien.map((item) => (
                                          <option style={{ color: 'black' }} value={item.maLoaiBien}>{item.name}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              }

                              <div className="biensoxe">
                                {activeFilter === 'qh_dshc' ?
                                  <div className="a">
                                    <table className="qh_res">
                                      <thead>
                                        <tr>
                                          {/* <th>Chọn</th> */}
                                          <th>STT</th>
                                          <th>Sửa</th>
                                          <th>Xóa</th>
                                          <th>Biển số</th>
                                          <th>Giá bán</th>
                                          <th>Tỉnh, Thành phố</th>
                                          <th>Loại xe</th>
                                          <th>Loại biển</th>
                                          {activeFilter === "qh_dsct" && <th>Ngày đấu giá</th>}
                                          <th>Ý nghĩa</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {contentData.map((item) => (
                                          <tr key={item.id}>
                                            {/* <td><input type="checkbox" className="select-checkbox" /></td> */}
                                            <td>{item.stt}</td>
                                            <td onClick={() => handleDetail(item.id)}><a className="qh_dangky" data-bien={"/"} href="javascript:void(0)">Sửa</a></td>
                                            <td onClick={() => handleDelete(item.id)}><a href="javascript:void(0)" style={{ backgroundColor: 'red', padding: '6px', borderRadius: '3px' }} >Xóa</a></td>
                                            <td><span>{item.bks}</span></td>
                                            <td>{formatCurrency(item.gia_dau_gia)}</td>
                                            <td>{provinces.find(i => i.matinh === item.ten_tinh)?.name}</td>
                                            <td>{loaixe.find(i => i.maLoaiXe === item.loai_xe)?.name}</td>
                                            <td>{loaibien.find(i => i.maLoaiBien === item.loai_bien)?.name}</td>
                                            {activeFilter === "qh_dsct" && <td>{item.time}</td>}
                                            <td onClick={() => { setIsModalDichOpen(true); setBks(item.bks.substring(3, item.bks.length)) }}><a href="javascript:void(0)" className="qh_dichynghia">Xem</a></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div> :
                                  <div className="a qh_tb_res3">
                                    <div className="a c1">
                                      <input
                                        type="text"
                                        required
                                        placeholder="Nhập biển số"
                                        autoComplete="off"
                                        value={bksSearch}
                                        onChange={handleChangeBksSearch}
                                      />
                                    </div>
                                    <div className="a c1">
                                      <input
                                        type="number"
                                        required
                                        placeholder="Nhập giá"
                                        autoComplete="off"
                                        value={price}
                                        onChange={handleChangePrice}
                                      />
                                    </div>
                                    <div className="qh_filter">
                                      <div className="b c2">
                                        <img decoding="async" src={i2} alt="select" />
                                        <div className="b1">
                                          <select className="dropdown"
                                            style={{
                                              marginBottom: '0',
                                              backgroundColor: 'transparent',
                                              border: 'none',
                                              color: 'white'
                                            }}
                                            value={maTinh}
                                            onChange={handleChangeMaTinh}
                                          >
                                            {provinces.map((province) => (
                                              <option style={{ color: 'black' }} value={province.matinh} >{province.name}</option >
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="b c3">
                                        <img decoding="async" src={i1} alt="select" />
                                        <div className="b1">
                                          <select className="dropdown"
                                            style={{
                                              marginBottom: '0',
                                              backgroundColor: 'transparent',
                                              border: 'none',
                                              color: 'white'
                                            }}
                                            onChange={handleChangeMaLoaiXe}
                                            value={maLoaiXe} >
                                            <option style={{ color: 'black' }} value="">Loại Xe</option>
                                            <option style={{ color: 'black' }} value="1">Xe con</option>
                                            <option style={{ color: 'black' }} value="4">Xe tải van</option>
                                            <option style={{ color: 'black' }} value="3">Xe tải</option>
                                            <option style={{ color: 'black' }} value="2">Xe khách</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="b c5">
                                        <img decoding="async" src={i1} alt="select" />
                                        <select className="dropdown"
                                          style={{
                                            marginBottom: '0',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            color: 'white'
                                          }}
                                          onChange={handleChangeTypeBks}
                                          value={typeBks} >
                                          <option style={{ color: 'black' }} value="">Chọn loại biển</option>
                                          <option style={{ color: 'black' }} value="2">Ngũ quý</option>
                                          <option style={{ color: 'black' }} value="9">Sảnh tiến</option>
                                          <option style={{ color: 'black' }} value="3">Tứ quý</option>
                                          <option style={{ color: 'black' }} value="4">Tam hoa</option>
                                          <option style={{ color: 'black' }} value="1">Thần tài</option>
                                          <option style={{ color: 'black' }} value="6">Lộc phát</option>
                                          <option style={{ color: 'black' }} value="7">Ông địa</option>
                                          <option style={{ color: 'black' }} value="18">Số gánh</option>
                                          <option style={{ color: 'black' }} value="10">Lặp đôi</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div>
                                      <button onClick={() => { callApiCreateAndUpdate(id)}} style={{ backgroundColor: '#01ab6f', borderRadius: '5px' }}>SAVE</button>
                                    </div>
                                  </div>
                                }
                                {activeFilter === 'qh_dshc' &&
                                  <div className="qh_pgn" data-ceil="2">
                                    <Pagination
                                      currentPage={currentPage}
                                      totalPages={totalPages}
                                      onPageChange={handlePageChange}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <footer id="footer" className="footer-wrapper">
          <section className="section qh_f1 dark" id="section_1815429417">
            <div className="bg section-bg fill bg-fill bg-loaded"></div>
            <div className="section-content relative">
              <div className="row row-small" id="row-201707142">
                <div id="col-1578809429" className="col small-12 large-12">
                  <div className="col-inner">
                    <h2>
                      <img
                        className="alignnone wp-image-2666"
                        src={logoNew}
                        alt=""
                        width="400"
                        height="80"
                      />
                    </h2>
                    <h2>Công TY Biển Số Đẹp Việt Nam</h2>
                    <h4>Chi nhánh tại các Thành Phố lớn :</h4>
                    <p>
                      HÀ NỘI: L7--66 Khu Đô Thị Athena Fullland Đại Kim, Hoàng Mai, Hà Nội.
                    </p>
                    <p>
                      TP HCM: 430 Lý Thái Tổ, Q10 , Tp HCM.
                    </p>
                    <p>
                      ĐÀ NẴNG: 75 Nguyễn Tri Phương, Thạc Gián, Q. Thanh Khê, Đà Nẵng.
                    </p>
                    <p>
                      NHA TRANG: 233 Nguyễn Thị Minh Khai, Phước Hoà, Nha Trang.
                    </p>
                    <p>
                      CẦN THƠ: 74 Xô Viết Nghệ Tĩnh, An Cư, Ninh Kiều, Cần Thơ.
                    </p>
                    <p>
                      <img
                        className="alignnone wp-image-61"
                        src={logoSaleNoti}
                        alt=""
                        width="200"
                        height="76"
                      />
                      <img
                        className="wp-image-239"
                        style={{ marginLeft: '20px' }}
                        src={flogo3}
                        alt=""
                        width="133"
                        height="40"
                      />
                      <img
                        className="wp-image-240"
                        style={{ marginLeft: '20px' }}
                        src={flogo1}
                        alt=""
                        width="65"
                        height="39"
                      />
                      <img
                        className="alignnone wp-image-567"
                        style={{ marginLeft: '20px' }}
                        src={logoNew}
                        alt=""
                        width="50"
                        height="60"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="absolute-footer dark medium-text-center small-text-center">
            <div className="container clearfix">
              <div className="footer-primary pull-left">
                <div className="copyright-footer"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Modal show={show} handleClose={handleClose} handleSendEmail={handleSendEmail} bks={bks} handlePhone={handlePhone} handleToEmail={handleToEmail} handleNote={handleNote} />
      <ModalDichbien isOpen={isModalDichOpen} onClose={() => setIsModalDichOpen(false)} bks={bks} />
    </div>
  );
}

export default Admin;