// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Ensure the modal itself is in front of the backdrop */
  color: black;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'Arial', sans-serif;
}

/* .close-button {
  background: 'red';
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
} */

.title {
  color: #c8412b;
  font-size: 24px;
  text-align: center;
  font-family: 'Dancing Script', cursive; /* Use a cursive font for title */
}

.highlight {
  color: #c8412b;
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

li {
  font-weight: bold;
}

p {
  margin-left: 20px;
  line-height: 1.5;
  font-size: 16px;
}

span.highlight {
  color: #c8412b;
  font-weight: bold;
  font-style: italic;
}
`, "",{"version":3,"sources":["webpack://./src/css/modal-dich-bien.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,wDAAwD;EACvE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,wCAAwC;EACxC,gCAAgC;AAClC;;AAEA;;;;;;;;GAQG;;AAEH;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,sCAAsC,EAAE,iCAAiC;AAC3E;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1001; /* Ensure the modal itself is in front of the backdrop */\n  color: black;\n}\n\n.modal-content {\n  background: #fff;\n  padding: 20px;\n  border-radius: 10px;\n  max-width: 600px;\n  width: 100%;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  font-family: 'Arial', sans-serif;\n}\n\n/* .close-button {\n  background: 'red';\n  border: none;\n  font-size: 20px;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n} */\n\n.title {\n  color: #c8412b;\n  font-size: 24px;\n  text-align: center;\n  font-family: 'Dancing Script', cursive; /* Use a cursive font for title */\n}\n\n.highlight {\n  color: #c8412b;\n  font-weight: bold;\n}\n\nul {\n  list-style: none;\n  padding: 0;\n  margin: 20px 0;\n}\n\nli {\n  font-weight: bold;\n}\n\np {\n  margin-left: 20px;\n  line-height: 1.5;\n  font-size: 16px;\n}\n\nspan.highlight {\n  color: #c8412b;\n  font-weight: bold;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
