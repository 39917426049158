// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping if content exceeds container width */
  max-width: 100%; /* Ensure the pagination stays within parent width */
  overflow: hidden; /* Hide overflow */
  margin-top: 15px;
}

.pagination button {
  margin: 0 5px;
  /* padding: 10px; */
  border: 1px solid #ddd;
  background-color: #01ab6f;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/css/paging.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe,EAAE,uDAAuD;EACxE,eAAe,EAAE,oDAAoD;EACrE,gBAAgB,EAAE,kBAAkB;EACpC,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".pagination {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap; /* Allows wrapping if content exceeds container width */\n  max-width: 100%; /* Ensure the pagination stays within parent width */\n  overflow: hidden; /* Hide overflow */\n  margin-top: 15px;\n}\n\n.pagination button {\n  margin: 0 5px;\n  /* padding: 10px; */\n  border: 1px solid #ddd;\n  background-color: #01ab6f;\n  cursor: pointer;\n}\n\n.pagination button.active {\n  background-color: #007bff;\n  color: white;\n}\n\n.pagination button:disabled {\n  cursor: not-allowed;\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
