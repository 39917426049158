// Modal.js
import React from 'react';
import './css/modal.css';
import { Button } from 'bootstrap';

const Modal = ({ show, handleClose, handleSendEmail, bks, handlePhone, handleToEmail, handleNote }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <div className="modal-header">
          <h2>Send Email</h2>
          <button onClick={handleClose} className="close-button">X</button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label>Biển:</label>
            <input type="text" required disabled value={bks} />
          </div>
          <div className="form-group">
            <label>Số điện thoại:</label>
            <input type="text" required onChange={handlePhone} />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input type="text" required onChange={handleToEmail} />
          </div>
          <div className="form-group">
            <label>Ghi chú thêm cho Admin:</label>
            <textarea name="text" onChange={handleNote}></textarea>
          </div>
          <button type="submit" className="submit-button" onClick={handleSendEmail}>Gửi</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;