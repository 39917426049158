import React, { useEffect, useState } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from 'axios';
import Modal from './Modal.js';
import Pagination from './Pagination';
import ModalDichbien from './ModalDichbien.js';

import 'flickity/css/flickity.css';

import './css/flatpickr.min.css';
import './css/flatsome.css';
import './css/style-text.css';
import './css/styles.css';
import { useNavigate } from 'react-router-dom';

import banner1 from './image/banner1.png';
import banner2 from './image/banner2.png';
import banner3 from './image/banner3.png';
import banner4 from './image/banner7.jpg';
import banner5 from './image/banner5.jpg';
import banner6 from './image/banner6.jpg';
import banner7 from './image/banner7.jpg';
import call from './image/call.png';
import flogo1 from './image/flogo1.png';
import flogo3 from './image/flogo3.png';
import i1 from './image/i1.svg';
import i2 from './image/i2.svg';
import i3 from './image/i3.svg';
import logoNew from './image/logo-new.jpg';
import logoSaleNoti from './image/logoSaleNoti.png';
import taisaochon from './image/taisaochon.jpg';
import thongke from './image/thongke.jpg';
import rb2 from './image/rb2.jpg';
import map from './image/map.png';
import bnq from './image/bnq.jpg';
import bth from './image/bth.jpg';
import bpt from './image/bpt.jpg';
import iconmenu from './image/icon-menu.png';

// Contants
import { API_BASE_URL_LOCAL, API_BASE_URL_SERVER } from './contants.js';


function Home() {
  const [activeFilter, setActiveFilter] = useState('qh_dshc');
  const [contentData, setContentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [show, setShow] = useState(false);
  const [bks, setBks] = useState('');
  const [index, setIndex] = useState(1);
  const [bksSearch, setBksSearch] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [maLoaiXe, setMaLoaiXe] = useState('');
  const [maTinh, setMaTinh] = useState('');
  const [sortBy, setSortBy] = useState('gia_dau_gia');
  const [phone, setPhone] = useState('');
  const [toEmail, setToEmail] = useState('');
  const [note, setNote] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalDichOpen, setIsModalDichOpen] = useState(false);
  const [dateBsdg, setDateBsdg] = useState('');
  const [typeBks, setTypeBks] = useState('');
  const [strRun, setStrRun] = useState('Biển số nổi bật ngày 17/08: 99A12345, 30A45678, 30A45678, 30A45678, 30A45678, 30A45678 ');
  const [loaibien, setLoaiBien] = useState([]);

  useEffect(() => {
    handleFilterClick('qh_dshc');
  }, []);

  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setIndex((pageNumber * 30) - 29);
    setCurrentPage(pageNumber);
  };

  const handleChangeBksSearch = (event) => {
    setBksSearch(event.target.value);
  };

  const handlePhone = (event) => {
    console.log(event.target.value)
    setPhone(event.target.value);
  };

  const handleNote = (event) => {
    setNote(event.target.value);
  };

  const handleToEmail = (event) => {
    setToEmail(event.target.value);
  };

  const handleChangeFromDate = (event) => {
    setFromDate(event.target.value);
  };

  const handleChangeToDate = (event) => {
    setToDate(event.target.value);
  };

  const handleChangeBsdg = (event) => {
    setDateBsdg(event.target.value);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleChangeMaLoaiXe = (event) => {
    const value = event.target.value;
    setMaLoaiXe(value);
  };

  const handleChangeMaTinh = (event) => {
    const value = event.target.value;
    setMaTinh(value);
  };

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);
  };

  const handleChangeTypeBks = (event) => {
    const value = event.target.value;
    setTypeBks(value);
  };

  const handleFilterClick = (filter) => {
    // reset search feilds
    setBksSearch('');
    setMaTinh('');
    setMaLoaiXe('');
    setTypeBks('');
    setSortBy('');
    setFromDate('');
    setToDate('');
    setDateBsdg('');
    handlePageChange(1);

    setContentData([]);
    setActiveFilter(filter);
    setCurrentPage(1);
    setTotalPages(1);

    // if (filter === 'qh_bnhc') {
    //   callApiDscb();
    // } else if (filter === 'qh_dsct') {
    //   callApiDsct();
    // } else if (filter === 'qh_bsdg') {
    //   callApiBsdg();
    // } else {
    //   callApiBshc();
    // }
  };

  useEffect(() => {
    console.log("activeFilter: ", activeFilter)
    setLoaiBien(loaibienVpa);
    if (activeFilter === 'qh_bnhc') {
      callApiDscb();
    } else if (activeFilter === 'qh_dsct') {
      callApiDsct();
    } else if (activeFilter === 'qh_bsdg') {
      callApiBsdg();
    } else {
      callApiBshc();
      setLoaiBien(loaibienCuaToi);
    }
  }, [currentPage, index, bksSearch, maLoaiXe, maTinh, sortBy, toDate, fromDate, dateBsdg, typeBks, activeFilter]);

  const callApiDscb = async () => {
    try {
      let size = 15;
      const params = new URLSearchParams({
        page: currentPage == null ? 1 : currentPage,
        size: size,
        q: bksSearch,
        ma_loai_xe: maLoaiXe,
        ma_tinh: maTinh,
        list_plate: typeBks
      }).toString() + `&sort=gia_dau_gia:${sortBy}`;
      // const response = await fetch(`https://vpa.com.vn/api/v1/bks/search?${params}`);
      const response = await fetch(`${API_BASE_URL_SERVER}bien-so?${params}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      parseVpaDataDscb(data.data);
      //paging data
      setTotalPages(Math.ceil(data.total / size));
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  const callApiDsct = async () => {
    console.log('fromDate: ', fromDate)
    console.log('toDate: ', toDate)
    try {
      let size = 15;
      const params = new URLSearchParams({
        plate_status: 'waiting_auction',
        page: currentPage == null ? 1 : currentPage,
        size: size,
        q: bksSearch,
        ma_loai_xe: maLoaiXe,
        ma_tinh: maTinh,
        start_date: fromDate,
        end_date: toDate,
        list_plate: typeBks
      }).toString() + `&sort=gia_dau_gia:${sortBy}`;
      const response = await fetch(`${API_BASE_URL_SERVER}bien-so?${params}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      parseVpaDataDsct(data.data);
      //paging data
      setTotalPages(Math.ceil(data.total / size));
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  const callApiBsdg = async () => {
    try {
      let size = 12;
      let body = {
        limit: size,
        page: currentPage == null ? 0 : currentPage - 1,
        provinceId: maTinh,
        search: bksSearch,
        typeVehicle: maLoaiXe
      };

      const response = await axios.post(`${API_BASE_URL_SERVER}bien-so/bsdg`, body);
      parseVpaDataBsdg(response.data.data);
      //paging data
      setTotalPages(Math.ceil(response.data.totalElements / size));
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  const callApiBshc = async () => {
    try {
      let size = 15;
      const params = new URLSearchParams({
        page: currentPage == null ? 1 : currentPage,
        size: size,
        bks: bksSearch,
        maTinh: maTinh,
        maLoaiXe: maLoaiXe,
        maLoaiBien: typeBks
      });
      // const response = await fetch(`https://vpa.com.vn/api/v1/bks/search?${params}`);
      const response = await fetch(`${API_BASE_URL_SERVER}bien-so/bsct/list?${params}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      parseDataBshc(data.content);
      //paging data
      setTotalPages(Math.ceil(data.totalElements / size));
    } catch (error) {
      // setError(error);
    } finally {
      // setLoading(false);
    }
  };

  const parseDataBshc = (vpaDataList) => {
    const data = [];
    {
      let stt = index;
      vpaDataList.map((item) => (
        data.push({
          "stt": stt,
          "id": item.id,
          "bks": item.bks,
          "gia_dau_gia": item.giaDauGia,
          "ten_tinh": provinces.find(i => i.matinh === item.maTinh)?.name,
          "loai_xe": loaixe.find(i => i.maLoaiXe === item.maLoaiXe)?.name,
          "loai_bien": loaibien.find(i => i.maLoaiBien === item.maLoaiBien)?.name
        }),
        stt = stt + 1
      ))
    }
    setContentData(data)
  }

  const parseVpaDataDscb = (vpaDataList) => {
    const data = [];
    {
      let stt = index;
      vpaDataList.map((item) => (
        data.push({
          "stt": stt,
          "id": item.id,
          "bks": item.bks,
          "gia_dau_gia": item.gia_dau_gia,
          "ten_tinh": item.tinh.ten,
          "loai_xe": item.loai_xe.noi_dung,
          "loai_bien": item.loai_bien[0].ten
        }),
        stt = stt + 1
      ))
    }
    setContentData(data)
  }

  const parseVpaDataDsct = (vpaDataList) => {
    const data = [];
    {
      let stt = index;
      vpaDataList.map((item) => (
        data.push({
          "stt": stt,
          "id": item.id,
          "bks": item.bks,
          "gia_dau_gia": item.gia_dau_gia,
          "ten_tinh": item.tinh.ten,
          "loai_xe": item.loai_xe.noi_dung,
          "loai_bien": item.loai_bien[0].ten,
          "time": parseDateInVietnamTime(item.auctionStartTime)
        }),
        stt = stt + 1
      ))
    }
    setContentData(data)
  }

  const parseVpaDataBsdg = (vpaDataList) => {
    console.log('vpaDataList: ', vpaDataList)
    vpaDataList.map((item) =>
      console.log(item)
    )
    const data = [];
    {
      let stt = index;
      vpaDataList.map((item) => (
        data.push({
          "stt": stt,
          "provinceSymbol": item.provinceSymbol,
          "series": item.series,
          "orders": item.orders,
          "ten_tinh": item.nameProvince,
          "loai_xe": item.type,
          "nameProvince": item.nameProvince,
          "timeLeft": calculateTimeFromNow(item.registerToDate)
        }),
        stt = stt + 1
      ))
    }
    setContentData(data)
  }

  const parseDateInVietnamTime = (dateString) => {
    const date = new Date(dateString);
    // Adjust for Vietnam time zone (UTC+7)
    date.setHours(date.getHours());
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();

    return `${hours}:${minutes} ${day}/${month}/${year}`;
  };

  const calculateTimeFromNow = (time) => {
    const futureDate = new Date(time);
    const currentDate = new Date();

    const timeDifference = futureDate - currentDate;
    if (timeDifference <= 0) {
      return "Đã hết hạn";
    }
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    let result = '';
    if (days > 0) {
      result += `${days} ngày, `;
    }
    if (hours > 0) {
      result += `${hours} giờ, `;
    }
    if (minutes > 0) {
      result += `${minutes} phút `;
    }

    return `${result}`;
  }

  // --------------- EMAIL
  const handleSendEmail = async (e) => {
    let subject = bks;

    if (activeFilter === 'qh_dshc') {
      subject = 'Đăng ký mua biển hiện có: ' + subject;
    } else if (activeFilter === 'qh_bnhc') {
      subject = 'Đăng ký mua biển bấm ngay sẵn có: ' + subject;
    } else if (activeFilter === 'qh_dsct') {
      subject = 'Đăng ký mua biển danh sách chính thức: ' + subject;
    } else if (activeFilter === 'qh_bsdg') {
      subject = 'Đăng ký mua biển sắp đấu giá: ' + subject;
    }

    let body = {
      subject: subject,
      body: 'Phone: ' + phone + '\nEmail: ' + toEmail + '\nGhi chú thêm: ' + note
    };

    try {
      await axios.post(`${API_BASE_URL_SERVER}send-email`, body);
    } catch (error) {
    }
    handleClose();
  };
  // -------------- //

  const categories = [
    {
      title: 'Biển ngũ quý',
      links: [
        { href: '/', text: 'Ngũ quý 1' },
        { href: '/', text: 'Ngũ quý 2' },
        { href: '/', text: 'Ngũ quý 3' },
        { href: '/', text: 'Ngũ quý 4' },
        { href: '/', text: 'Ngũ quý 5' },
        { href: '/', text: 'Ngũ quý 6' },
        { href: '/', text: 'Ngũ quý 7' },
        { href: '/', text: 'Ngũ quý 8' },
        { href: '/', text: 'Ngũ quý 9' },
        { href: '/', text: 'Năm sinh' }
      ]
    },
    {
      title: 'Biển tứ quý',
      links: [
        { href: '/', text: 'Tứ quý 1' },
        { href: '/', text: 'Tứ quý 2' },
        { href: '/', text: 'Tứ quý 3' },
        { href: '/', text: 'Tứ quý 4' },
        { href: '/', text: 'Tứ quý 5' },
        { href: '/', text: 'Tứ quý 6' },
        { href: '/', text: 'Tứ quý 7' },
        { href: '/', text: 'Tứ quý 8' },
        { href: '/', text: 'Tứ quý 9' }
      ]
    },
    {
      title: 'Biển tam hoa',
      links: [
        { href: '/', text: 'Tam hoa 1' },
        { href: '/', text: 'Tam hoa 2' },
        { href: '/', text: 'Tam hoa 3' },
        { href: '/', text: 'Tam hoa 4' },
        { href: '/', text: 'Tam hoa 5' },
        { href: '/', text: 'Tam hoa 6' },
        { href: '/', text: 'Tam hoa 7' },
        { href: '/', text: 'Tam hoa 8' },
        { href: '/', text: 'Tam hoa 9' }
      ]
    },
    {
      title: 'Biển phong thủy',
      links: [
        { href: '/', text: 'Phong thủy tổng 2' },
        { href: '/', text: 'Phong thủy tổng 3' },
        { href: '/', text: 'Phong thủy tổng 4' },
        { href: '/', text: 'Phong thủy tổng 5' },
        { href: '/', text: 'Phong thủy tổng 6' },
        { href: '/', text: 'Phong thủy tổng 7' },
        { href: '/', text: 'Phong thủy tổng 8' },
        { href: '/', text: 'Phong thủy tổng 9' },
        { href: '/', text: 'Phong thủy tổng 10' }
      ]
    },
    {
      title: 'Biển theo xe',
      links: [
        { href: '/1', text: 'Xe con' },
        { href: '/2', text: 'Xe tải van' },
        { href: '/3', text: 'Xe tải - Xe bán tải' },
        { href: '/4', text: 'Xe khách' }
      ]
    }
  ];

  const provinces = [
    { name: "Tỉnh Thành", matinh: "" },
    { name: "Hà Nội", matinh: "01" },
    { name: "Hà Giang", matinh: "02" },
    { name: "Cao Bằng", matinh: "04" },
    { name: "Bắc Kạn", matinh: "06" },
    { name: "Tuyên Quang", matinh: "08" },
    { name: "Lào Cai", matinh: "10" },
    { name: "Điện Biên", matinh: "11" },
    { name: "Lai Châu", matinh: "12" },
    { name: "Sơn La", matinh: "14" },
    { name: "Yên Bái", matinh: "15" },
    { name: "Hòa Bình", matinh: "17" },
    { name: "Thái Nguyên", matinh: "19" },
    { name: "Lạng Sơn", matinh: "20" },
    { name: "Quảng Ninh", matinh: "22" },
    { name: "Bắc Giang", matinh: "24" },
    { name: "Phú Thọ", matinh: "25" },
    { name: "Vĩnh Phúc", matinh: "26" },
    { name: "Bắc Ninh", matinh: "27" },
    { name: "Hải Dương", matinh: "30" },
    { name: "Hải Phòng", matinh: "31" },
    { name: "Hưng Yên", matinh: "33" },
    { name: "Thái Bình", matinh: "34" },
    { name: "Hà Nam", matinh: "35" },
    { name: "Nam Định", matinh: "36" },
    { name: "Ninh Bình", matinh: "37" },
    { name: "Thanh Hóa", matinh: "38" },
    { name: "Nghệ An", matinh: "40" },
    { name: "Hà Tĩnh", matinh: "42" },
    { name: "Quảng Bình", matinh: "44" },
    { name: "Quảng Trị", matinh: "45" },
    { name: "Thừa Thiên Huế", matinh: "46" },
    { name: "Đà Nẵng", matinh: "48" },
    { name: "Quảng Nam", matinh: "49" },
    { name: "Quảng Ngãi", matinh: "51" },
    { name: "Bình Định", matinh: "52" },
    { name: "Phú Yên", matinh: "54" },
    { name: "Khánh Hòa", matinh: "56" },
    { name: "Ninh Thuận", matinh: "58" },
    { name: "Bình Thuận", matinh: "60" },
    { name: "Kon Tum", matinh: "62" },
    { name: "Gia Lai", matinh: "64" },
    { name: "Đắk Lắk", matinh: "66" },
    { name: "Đắk Nông", matinh: "67" },
    { name: "Lâm Đồng", matinh: "68" },
    { name: "Bình Phước", matinh: "70" },
    { name: "Tây Ninh", matinh: "72" },
    { name: "Bình Dương", matinh: "74" },
    { name: "Đồng Nai", matinh: "75" },
    { name: "Bà Rịa - Vũng Tàu", matinh: "77" },
    { name: "Hồ Chí Minh", matinh: "79" },
    { name: "Long An", matinh: "80" },
    { name: "Tiền Giang", matinh: "82" },
    { name: "Bến Tre", matinh: "83" },
    { name: "Trà Vinh", matinh: "84" },
    { name: "Vĩnh Long", matinh: "86" },
    { name: "Đồng Tháp", matinh: "87" },
    { name: "An Giang", matinh: "89" },
    { name: "Kiên Giang", matinh: "91" },
    { name: "Cần Thơ", matinh: "92" },
    { name: "Hậu Giang", matinh: "93" },
    { name: "Sóc Trăng", matinh: "94" },
    { name: "Bạc Liêu", matinh: "95" },
    { name: "Cà Mau", matinh: "96" }
  ];

  const userOrder = [
    {
      name: "Lộc Thị Dương",
      sim: "092246xxxx",
      time: "17/08/2024 13:55:45",
      status: "Đang xác nhận",
      statusColor: "#ad0909",
    },
    {
      name: "Lộc Thị Dương",
      sim: "092246xxxx",
      time: "17/08/2024 13:25:33",
      status: "Đang xác nhận",
      statusColor: "#ad0909",
    },
    {
      name: "",
      sim: "096651xxxx",
      time: "17/08/2024 12:32:06",
      status: "Đang xác nhận",
      statusColor: "#ad0909",
    },
    {
      name: "",
      sim: "087677xxxx",
      time: "17/08/2024 10:55:37",
      status: "Sim đang GD",
      statusColor: "green",
    },
    {
      name: "Lại Thế Kim Trung",
      sim: "092658xxxx",
      time: "17/08/2024 10:19:30",
      status: "Đang xác nhận",
      statusColor: "#ad0909",
    },
    {
      name: "Lại Thế Kim Trung",
      sim: "092658xxxx",
      time: "17/08/2024 10:17:31",
      status: "Đang xác nhận",
      statusColor: "#ad0909",
    },
    {
      name: "Lại Thế Kim Trung",
      sim: "092358xxxx",
      time: "17/08/2024 10:18:45",
      status: "Đang xác nhận",
      statusColor: "#ad0909",
    },
    {
      name: "A Tình",
      sim: "081372xxxx",
      time: "17/08/2024 09:52:35",
      status: "Đang xác nhận",
      statusColor: "#ad0909",
    },
  ];

  const loaixe = [
    {
      name: "Xe con", maLoaiXe: "1"
    }, {
      name: "Xe tải van", maLoaiXe: "4"
    }, {
      name: "Xe tải", maLoaiXe: "3"
    }, {
      name: "Xe khách", maLoaiXe: "2"
    }
  ];

  const loaibienCuaToi = [
    {
      name: "Ngũ quý", maLoaiBien: "2"
    }, {
      name: "Sảnh tiến", maLoaiBien: "9"
    }, {
      name: "Tứ quý", maLoaiBien: "3"
    }, {
      name: "Tam hoa", maLoaiBien: "4"
    }, {
      name: "Thần tài", maLoaiBien: "1"
    }, {
      name: "Lộc phát", maLoaiBien: "6"
    }, {
      name: "Ông địa", maLoaiBien: "7"
    }, {
      name: "Số gánh", maLoaiBien: "18"
    }, {
      name: "Lặp đôi", maLoaiBien: "10"
    }, {
      name: "Tam đầu hoả", maLoaiBien: "11"
    }, {
      name: "Biển phong thuỷ", maLoaiBien: "12"
    }, {
      name: "Biển dễ nhớ", maLoaiBien: "13"
    }, {
      name: "Biển đuôi 69 96", maLoaiBien: "14"
    }, {
      name: "Tài phát", maLoaiBien: "15"
    }
  ]

  const loaibienVpa = [
    {
      name: "Ngũ quý", maLoaiBien: "2"
    }, {
      name: "Sảnh tiến", maLoaiBien: "9"
    }, {
      name: "Tứ quý", maLoaiBien: "3"
    }, {
      name: "Tam hoa", maLoaiBien: "4"
    }, {
      name: "Thần tài", maLoaiBien: "1"
    }, {
      name: "Lộc phát", maLoaiBien: "6"
    }, {
      name: "Ông địa", maLoaiBien: "7"
    }, {
      name: "Số gánh", maLoaiBien: "18"
    }, {
      name: "Lặp đôi", maLoaiBien: "10"
    }
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,          // Enable auto-slide
    autoplaySpeed: 3000,     // Time in milliseconds (3 seconds)
    pauseOnHover: true,      // Pause auto-slide when hovering over the slider
  };

  const formatCurrency = (value) => {
    if (!value) return '';

    const amount = parseFloat(value);
    const formatter = new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    });

    return formatter.format(amount);
  };

  return (
    <div className="page-template page-template-page-blank page-template-page-blank-php page page-id-635 lightbox nav-dropdown-has-arrow nav-dropdown-has-shadow nav-dropdown-has-border">
      <div id="wrapper">
        <header className="header has-sticky sticky-jump">
          <div className="header-wrapper">
            <div id="masthead" className="header-main nav-dark">
              <div className="header-inner flex-row container logo-left medium-logo-center" role="navigation">
                {/* Logo */}
                <div id="logo" className="flex-col logo">
                  <a href="" title="Biển số đẹp" rel="home">
                    {/* <img width="857" height="1024" src={logoNew} className="header_logo header-logo" alt="Biển số đẹp" />
                    <img width="857" height="1024" src={logoNew} className="header-logo-dark" alt="Biển số đẹp" /> */}
                    <img
                      style={{ filter: 'none' }}
                      className="alignnone wp-image-2666"
                      src={logoNew}
                      alt=""
                      width="400"
                      height="80"
                    />
                  </a>
                </div>

                <div className="flex-col hide-for-medium flex-right"
                  style={{ marginLeft: '15%', backgroundColor: '#cd401a', borderRadius: '3px', height: '57px' }}>
                  <ul className="header-nav header-nav-main nav nav-right nav-uppercase" style={{ textAlign: 'center' }}>
                    <li className="html custom html_topbar_left">
                      <a href="tel:0923467777" className="qh_a">
                        <i style={{ marginLeft: '5px' }}><img src={map} alt="icon" /></i>
                        <span>Cửa hàng</span>
                        <span>50 Chi nhánh</span>
                      </a>
                    </li>
                  </ul>
                </div>

                {/* Mobile Left Elements */}
                <div>
                  <div className="flex-col show-for-medium flex-left">
                    <ul className="mobile-nav nav nav-left">
                      <li className="nav-icon has-icon">
                        <a
                          href="#"
                          onClick={toggleMenu}
                          className="is-small"
                          aria-label="Menu"
                          aria-controls="main-menu"
                          aria-expanded={isMenuOpen}>
                          <i className=""><img src={iconmenu} alt="icon" /></i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className={`slide-menu ${isMenuOpen ? 'open' : ''}`}>
                    <div className="menu-content">
                      <ul>
                        <li>
                          <Link to="/" className="nav-top-link">Trang chủ</Link>
                        </li>
                        <li>
                          <Link to="/dich-bien" className="nav-top-link">Dịch biển xe</Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {isMenuOpen && <div className="menu-overlay" onClick={toggleMenu}></div>}
                </div>
                <div className="flex-col hide-for-medium flex-left flex-grow">
                  <ul className="header-nav header-nav-main nav nav-left nav-uppercase" style={{ marginLeft: '10%' }}>
                    <li id="menu-item-639" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-635 current_page_item menu-item-639 active menu-item-design-default">
                      <Link to="/" aria-current="page" className="nav-top-link">Trang chủ</Link>
                    </li>
                    <li id="menu-item-639" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-635 current_page_item menu-item-639 active menu-item-design-default">
                      <Link to="/dich-bien" aria-current="page" className="nav-top-link">Dịch biển xe</Link>
                    </li>
                  </ul>
                </div>
                {/* Right Elements */}
                <div className="flex-col hide-for-medium flex-right">
                  <ul className="header-nav header-nav-main nav nav-right nav-uppercase">
                    <li className="html custom html_topbar_left">
                      <a href="tel:0923467777" className="qh_a">
                        <i><img src={call} alt="icon" /></i>
                        <span>09452.77777</span>
                        <span>Gọi để được hỗ trợ</span>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* Mobile Right Elements */}
                <div className="flex-col show-for-medium flex-right">
                  <ul className="mobile-nav nav nav-right"></ul>
                </div>
              </div>
              <div className="container">
                <div className="top-divider full-width"></div>
              </div>
            </div>
            <div className="header-bg-container fill">
              <div className="header-bg-image fill"></div>
              <div className="header-bg-color fill"></div>
            </div>
          </div>
        </header>

        <div className="slider-wrapper relative qh_slide" id="slider-1663031332">
          <div >
            <Slider style={{ backgroundColor: 'white' }} {...settings}>
              <div>
                <img src={banner1} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
              <div style={{ padding: "3px" }}>
                <img src={banner2} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
              <div style={{ padding: "3px" }}>
                <img src={banner4} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
              <div style={{ padding: "3px" }}>
                <img src={banner5} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
              <div style={{ padding: "3px" }}>
                <img src={banner3} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
              <div style={{ padding: "3px" }}>
                <img src={banner6} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
              <div style={{ padding: "3px" }}>
                <img src={banner7} alt={`Slide ${index + 1}`} style={{ width: "100%", padding: "5px" }} />
              </div>
            </Slider>
          </div>
          {/* <div className="loading-spin dark large centered"></div> */}
          <div className="marquee-container">
            <div className="marquee">
              {strRun}
            </div>
          </div>
        </div>

        <main id="main" className="dark dark-page-wrapper">
          <div id="content" role="main" className="content-area">
            <section className="section dark" id="section_710753446">
              <div className="bg section-bg fill bg-fill bg-loaded back-ground-co"></div>
              <div className="section-content relative">
                <div class="row row-small row-full-width" id="row-652602724">
                  <div id="col-1854014999" className="col hide-for-medium medium-2 small-12 large-2">
                    <div className="col-inner">
                      {categories.map((category, index) => (
                        <div className="qh_bientheoloai" style={{ marginTop: index === 0 ? '0' : '20px' }} key={index}>
                          <h2>{category.title}</h2>
                          {category.links.map((link, linkIndex) => (
                            <a key={linkIndex}>{link.text}</a>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div id="col-637516760" className="col medium-12 small-12 large-8">
                    <div className="col-inner">
                      <div className="row row-small qh_widt">
                        <div className="col large-12">
                          <div className="col-inner">
                            <div className="col-inner">
                              <img
                                src={taisaochon}
                                alt=""
                              />
                            </div>
                            {/* <h1 style={{ marginTop: '3%', marginBottom: '3%' }}>Biển số đẹp</h1> */}
                            <ul className="qh_ftb" style={{ marginBottom: '5%' }}>
                              <li
                                className={activeFilter === 'qh_dshc' ? 'active' : ''}
                                onClick={() => handleFilterClick('qh_dshc')}
                              >
                                <b>Danh sách biển hiện có</b>
                              </li>
                              <li
                                className={activeFilter === 'qh_bnhc' ? 'active' : ''}
                                onClick={() => handleFilterClick('qh_bnhc')}
                              >
                                <b>Biển bấm ngay hiện có</b>
                              </li>
                              <li
                                className={activeFilter === 'qh_dsct' ? 'active' : ''}
                                onClick={() => handleFilterClick('qh_dsct')}
                              >
                                <b>Danh sách chính thức</b>
                              </li>
                              <li
                                className={activeFilter === 'qh_bsdg' ? 'active' : ''}
                                onClick={() => handleFilterClick('qh_bsdg')}
                              >
                                <b>Biển sắp đấu giá</b>
                              </li>
                            </ul>
                            <div
                              // className={`qh_dshc qh_filterchung ${activeFilter === 'qh_dshc' ? 'active' : ''}`}
                              id="bienxevip"
                              data-aff=".qh_dshc"
                            >
                              <div className="qh_filter">
                                <div className="a c1">
                                  <img decoding="async" src={i3} alt="input" />
                                  <input
                                    type="text"
                                    placeholder="Nhập tìm kiếm biển số xe"
                                    autoComplete="off"
                                    value={bksSearch}
                                    onChange={handleChangeBksSearch}
                                  />
                                </div>
                                <div className="b c2">
                                  <img decoding="async" src={i2} alt="select" />
                                  <div className="b1">
                                    <select className="dropdown"
                                      style={{
                                        marginBottom: '0',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        color: 'white'
                                      }}
                                      value={maTinh}
                                      onChange={handleChangeMaTinh}
                                    >
                                      {provinces.map((province) => (
                                        <option style={{ color: 'black' }} value={province.matinh} >{province.name}</option >
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="b c3">
                                  <img decoding="async" src={i1} alt="select" />
                                  <div className="b1">
                                    <select className="dropdown"
                                      style={{
                                        marginBottom: '0',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        color: 'white'
                                      }}
                                      onChange={handleChangeMaLoaiXe}
                                      value={maLoaiXe} >
                                      <option style={{ color: 'black' }} value="">Loại Xe</option>
                                      <option style={{ color: 'black' }} value="1">Xe con</option>
                                      <option style={{ color: 'black' }} value="4">Xe tải van</option>
                                      <option style={{ color: 'black' }} value="3">Xe tải</option>
                                      <option style={{ color: 'black' }} value="2">Xe khách</option>
                                    </select>
                                  </div>
                                </div>
                                {activeFilter !== "qh_bsdg" &&
                                  <div className="b c5">
                                    <img decoding="async" src={i1} alt="select" />
                                    <select className="dropdown"
                                      style={{
                                        marginBottom: '0',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        color: 'white'
                                      }}
                                      onChange={handleChangeTypeBks}
                                      value={typeBks} >
                                      <option style={{ color: 'black' }} value="">Chọn loại biển</option>

                                      {loaibien.map((item) => (
                                          <option style={{ color: 'black' }} value={item.maLoaiBien}>{item.name}</option>
                                      ))}
                                      {/* <option style={{ color: 'black' }} value="2">Ngũ quý</option>
                                      <option style={{ color: 'black' }} value="9">Sảnh tiến</option>
                                      <option style={{ color: 'black' }} value="3">Tứ quý</option>
                                      <option style={{ color: 'black' }} value="4">Tam hoa</option>
                                      <option style={{ color: 'black' }} value="1">Thần tài</option>
                                      <option style={{ color: 'black' }} value="6">Lộc phát</option>
                                      <option style={{ color: 'black' }} value="7">Ông địa</option>
                                      <option style={{ color: 'black' }} value="18">Số gánh</option>
                                      <option style={{ color: 'black' }} value="10">Lặp đôi</option> */}
                                    </select>
                                  </div>}
                                {activeFilter === "qh_dsct" &&
                                  <div className="b c3"  >
                                    <img decoding="async" src={i1} alt="select" />
                                    <div className="b1">
                                      {/* <p data-save="0">Đấu giá từ</p> */}
                                      <input
                                        type="date"
                                        className="dropdown"
                                        style={{
                                          marginBottom: '0',
                                          backgroundColor: 'transparent',
                                          border: 'none',
                                          color: 'white',
                                        }}
                                        onChange={handleChangeFromDate}
                                        value={fromDate}
                                      />
                                    </div>
                                  </div>
                                }
                                {activeFilter === "qh_dsct" &&
                                  <div className="b c3">
                                    <img decoding="async" src={i1} alt="select" />
                                    <div className="b1">
                                      {/* <p data-save="0">Đấu giá từ</p> */}
                                      <input
                                        type="date"
                                        className="dropdown"
                                        style={{
                                          marginBottom: '0',
                                          backgroundColor: 'transparent',
                                          border: 'none',
                                          color: 'white',
                                        }}
                                        onChange={handleChangeToDate}
                                        value={toDate}
                                      />
                                    </div>
                                  </div>
                                }
                                {activeFilter !== "qh_bsdg" &&
                                  <div className="b c5">
                                    <img decoding="async" src={i1} alt="select" />
                                    <select className="dropdown"
                                      style={{
                                        marginBottom: '0',
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        color: 'white'
                                      }}
                                      onChange={handleChangeSortBy}
                                      value={sortBy} >
                                      <option style={{ color: 'black' }} value="">Sắp Xếp</option>
                                      <option style={{ color: 'black' }} value="DESC">Thấp dần</option>
                                      <option style={{ color: 'black' }} value="ASC">Tăng dần</option>
                                    </select>
                                  </div>}
                              </div>
                              <div className="biensoxe">
                                {activeFilter !== 'qh_bsdg' ?
                                  <div className="a">
                                    <table className="qh_res">
                                      <thead>
                                        <tr>
                                          {/* <th>Chọn</th> */}
                                          <th>STT</th>
                                          <th>Liên hệ</th>
                                          <th>Biển số</th>
                                          <th>Giá bán</th>
                                          <th>Tỉnh, Thành phố</th>
                                          <th>Loại xe</th>
                                          <th>Loại biển</th>
                                          {activeFilter === "qh_dsct" && <th>Ngày đấu giá</th>}
                                          <th>Ý nghĩa</th>
                                          <th>Liên hệ</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {contentData.map((item) => (
                                          <tr key={item.id}>
                                            {/* <td><input type="checkbox" className="select-checkbox" /></td> */}
                                            <td>{item.stt}</td>
                                            <td onClick={() => { handleShow(); setBks(item.bks) }}><a className="qh_dangky" data-bien={"/"} href="javascript:void(0)">Đăng ký</a></td>
                                            <td><span>{item.bks}</span></td>
                                            <td>{formatCurrency(item.gia_dau_gia)}</td>
                                            <td>{item.ten_tinh}</td>
                                            <td>{item.loai_xe}</td>
                                            <td>{item.loai_bien}</td>
                                            {activeFilter === "qh_dsct" && <td>{item.time}</td>}
                                            <td onClick={() => { setIsModalDichOpen(true); setBks(item.bks.substring(3, item.bks.length)) }}><a href="javascript:void(0)" className="qh_dichynghia">Xem</a></td>
                                            <td onClick={() => { handleShow(); setBks(item.bks) }}><a className="qh_dangky" data-bien={"/"} href="javascript:void(0)">Đăng ký</a></td>
                                            {/* <button onClick={handleShow} className="open-button">Open Modal</button> */}
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div> :
                                  <div className="a qh_tb_res3">
                                    <div className="qh_tb_res2">
                                      {contentData.map((item) => (
                                        <div className="aa">
                                          <span className="span1">{item.provinceSymbol}{item.series}<br />{item.orders}</span>
                                          <div className="aa1">
                                            {item.loai_xe === 1 && <span>Xe con</span>}
                                            {item.loai_xe === 2 && <span>Xe khách</span>}
                                            {item.loai_xe === 3 && <span>Xe tải</span>}
                                            {item.loai_xe === 4 && <span>Xe tải van</span>}
                                            <span>{item.nameProvince}</span>
                                          </div>
                                          <div className="aa2">
                                            <span>
                                              <svg viewBox="64 64 896 896" focusable="false" data-icon="clock-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                                                <path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z"></path>
                                              </svg>
                                            </span>
                                            <div>
                                              <span>Thời gian đăng ký còn lại</span>
                                              <span data-now="1723638610" data-end="1724226300">{item.timeLeft}</span>
                                            </div>
                                          </div>
                                          <a href="javascript:void(0)" onClick={() => { handleShow(); setBks(item.provinceSymbol + item.series + item.orders) }} data-bien="30M-018.86 ()" target="_self" className="qh_dkm">Đăng ký mua</a>
                                          <a href="javascript:void(0)" onClick={() => { setIsModalDichOpen(true); setBks(item.orders) }} className="qh_xct" data-bien="30M-018.86" data-time1="14:52 13/08/2024" data-time2="14:45 21/08/2024" data-time3="15:15 21/08/2024" data-time4="14:45 18/08/2024">Dịch biển</a>
                                        </div>
                                      ))}
                                    </div>
                                  </div>}
                                <div className="qh_pgn" data-ceil="2">
                                  <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-inner" style={{display: 'flex'}}>
                      {/* <img
                        src={bnq}
                        alt=""
                        style={{width: '45%', height: '45%'}}
                      /> */}
                      <img
                        src={bth}
                        alt=""
                        style={{width: '48%', height: '48%', borderRadius: '10px'}}
                      />
                      <img
                        src={bpt}
                        alt=""
                        style={{width: '48%', height: '48%', marginLeft: '4%', borderRadius: '10px'}}
                      />
                    </div>
                    <div className="testimonials">
                      <h2>KHÁCH HÀNG ĐÁNH GIÁ</h2>
                      <div className="testimonials-container">
                        <div className="testimonial">
                          <h3>Tuệ Nhi</h3>
                          <p>
                            Lúc mua xe mình rất sợ bấm phải biển có phong thủy không tốt, rất may mình được
                            một người bạn giới thiệu đến Kho biển số đẹp để có thể chọn được biển hợp với
                            phong thủy mà chính chủ sang tên.
                          </p>
                        </div>
                        <div className="testimonial">
                          <h3>Long Nguyễn</h3>
                          <p>
                            Mình làm kinh doanh nên muốn tìm biển thần tài, tình cờ mình thấy bên Kho biển số
                            đẹp có biển 30F 777.79 quá đúng ý mình. Các thủ tục sang tên chính chủ nhanh gọn,
                            mình rất hài lòng.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-inner">
                      <img
                        src={thongke}
                        alt=""
                        style={{ marginTop: '20px' }}
                      />
                    </div> */}
                  </div>
                  <div id="col-547865803" className="col show-for-medium medium-12 small-12 large-2">
                    <div className="col-inner">
                      <div className="qh_bientheoloai" style={{ marginTop: '20px' }}>
                        <h2>Biển ngũ quý</h2>
                        <a href="/">Ngũ quý 1</a>
                        <a href="/">Ngũ quý 2</a>
                        <a href="/">Ngũ quý 3</a>
                        <a href="/">Ngũ quý 4</a>
                        <a href="/">Ngũ quý 5</a>
                        <a href="/">Ngũ quý 6</a>
                        <a href="/">Ngũ quý 7</a>
                        <a href="/">Ngũ quý 8</a>
                        <a href="/">Ngũ quý 9</a>
                        <a href="/">Năm sinh</a>
                      </div>
                      <div className="qh_bientheoloai" style={{ marginTop: '20px' }}>
                        <h2>Biển tứ quý</h2>
                        <a href="/">Tứ quý 1</a>
                        <a href="/">Tứ quý 2</a>
                        <a href="/">Tứ quý 3</a>
                        <a href="/">Tứ quý 4</a>
                        <a href="/">Tứ quý 5</a>
                        <a href="/">Tứ quý 6</a>
                        <a href="/">Tứ quý 7</a>
                        <a href="/">Tứ quý 8</a>
                        <a href="/">Tứ quý 9</a>
                      </div>
                      <div className="qh_bientheoloai" style={{ marginTop: '20px' }}>
                        <h2>Biển tam hoa</h2>
                        <a href="/">Tam hoa 1</a>
                        <a href="/">Tam hoa 2</a>
                        <a href="/">Tam hoa 3</a>
                        <a href="/">Tam hoa 4</a>
                        <a href="/">Tam hoa 5</a>
                        <a href="/">Tam hoa 6</a>
                        <a href="/">Tam hoa 7</a>
                        <a href="/">Tam hoa 8</a>
                        <a href="/">Tam hoa 9</a>
                      </div>
                      <div className="qh_bientheoloai" style={{ marginTop: '20px' }}>
                        <h2>Biển phong thủy</h2>
                        <a href="/">Phong thủy tổng 2</a>
                        <a href="/">Phong thủy tổng 3</a>
                        <a href="/">Phong thủy tổng 4</a>
                        <a href="/">Phong thủy tổng 5</a>
                        <a href="/">Phong thủy tổng 6</a>
                        <a href="/">Phong thủy tổng 7</a>
                        <a href="/">Phong thủy tổng 8</a>
                        <a href="/">Phong thủy tổng 9</a>
                        <a href="/">Phong thủy tổng 10</a>
                      </div>
                    </div>
                  </div>
                  <div id="col-1027245009" className="col medium-12 small-12 large-2">
                    <div className="col-inner">
                      {/* <div className="qh_bientheoloai" style={{ marginTop: '20px' }}>
                        <h2>Biển theo tỉnh</h2>
                        <div className="provinces">
                          {provinces.map((province) => (
                            <a>
                              {province.name}
                            </a>
                          ))}
                        </div>
                      </div> */}
                      <div className="col-inner">
                        <img style={{borderRadius: '10px'}}
                          src={bnq}
                          alt=""
                        />
                      </div>
                      <div className="qh_bientheoloai" style={{ marginTop: '20px' }}>
                        <h2 style={{ backgroundColor: 'red' }}>Đặt sim mới</h2>
                        <div className="provinces"
                          style={{ backgroundColor: 'white' }}>
                          {userOrder.map((order, index) => (
                            <a key={index}>
                              <div className="a1">
                                <div className="khach">
                                  <img
                                    src="https://sodepami.vn/img/khach-ds.png"
                                    alt="khach dat sim"
                                  />
                                </div>
                                <div className="name">
                                  Khách <b>{order.name}</b>
                                  <br />
                                  Đặt sim: <b>{order.sim}</b>
                                </div>
                                <div>Lúc: {order.time}</div>
                                <div>
                                  <font color={order.statusColor} className="tthome">
                                    <b>{order.status}</b>
                                  </font>
                                </div>
                              </div>
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <footer id="footer" className="footer-wrapper">
          <section className="section qh_f1 dark" id="section_1815429417">
            <div className="bg section-bg fill bg-fill bg-loaded"></div>
            <div className="section-content relative">
              <div className="row row-small" id="row-201707142">
                <div id="col-1578809429" className="col small-12 large-12">
                  <div className="col-inner">
                    <h2>
                      <img
                        className="alignnone wp-image-2666"
                        src={logoNew}
                        alt=""
                        width="400"
                        height="80"
                      />
                    </h2>
                    <h2>Công TY Biển Số Đẹp Việt Nam</h2>
                    <h4>Chi nhánh tại các Thành Phố lớn :</h4>
                    <p>
                      HÀ NỘI: L7--66 Khu Đô Thị Athena Fullland Đại Kim, Hoàng Mai, Hà Nội.
                    </p>
                    <p>
                      TP HCM: 430 Lý Thái Tổ, Q10 , Tp HCM.
                    </p>
                    <p>
                      ĐÀ NẴNG: 75 Nguyễn Tri Phương, Thạc Gián, Q. Thanh Khê, Đà Nẵng.
                    </p>
                    <p>
                      NHA TRANG: 233 Nguyễn Thị Minh Khai, Phước Hoà, Nha Trang.
                    </p>
                    <p>
                      CẦN THƠ: 74 Xô Viết Nghệ Tĩnh, An Cư, Ninh Kiều, Cần Thơ.
                    </p>
                    <p>
                      <img
                        className="alignnone wp-image-61"
                        src={logoSaleNoti}
                        alt=""
                        width="200"
                        height="76"
                      />
                      <img
                        className="wp-image-239"
                        style={{ marginLeft: '20px' }}
                        src={flogo3}
                        alt=""
                        width="133"
                        height="40"
                      />
                      <img
                        className="wp-image-240"
                        style={{ marginLeft: '20px' }}
                        src={flogo1}
                        alt=""
                        width="65"
                        height="39"
                      />
                      <img
                        className="alignnone wp-image-567"
                        style={{ marginLeft: '20px' }}
                        src={logoNew}
                        alt=""
                        width="50"
                        height="60"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="absolute-footer dark medium-text-center small-text-center">
            <div className="container clearfix">
              <div className="footer-primary pull-left">
                <div className="copyright-footer"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <a
        href="https://www.facebook.com/profile.php?id=61551142997153"
        style={{
          position: 'fixed',
          bottom: '160px',
          right: '20px',
          zIndex: 10,
          width: '51px',
          height: '51px',
        }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none">
          <path
            d="M4 17.4167C4 12.6709 4 10.298 4.93822 8.49188C5.72885 6.96985 6.96985 5.72885 8.49188 4.93822C10.298 4 12.6709 4 17.4167 4H30.5833C35.3291 4 37.702 4 39.5081 4.93822C41.0301 5.72885 42.2711 6.96985 43.0618 8.49188C44 10.298 44 12.6709 44 17.4167V30.5833C44 35.3291 44 37.702 43.0618 39.5081C42.2711 41.0301 41.0301 42.2711 39.5081 43.0618C37.702 44 35.3291 44 30.5833 44H17.4167C12.6709 44 10.298 44 8.49188 43.0618C6.96985 42.2711 5.72885 41.0301 4.93822 39.5081C4 37.702 4 35.3291 4 30.5833V17.4167Z"
            fill="url(#paint0_linear_149_671)"
          />
          <path
            d="M31.7779 29.5555L32.6667 23.9999H27.3334V20.111C27.3334 18.5555 27.889 17.3333 30.3334 17.3333H32.889V12.2222C31.4445 11.9999 29.889 11.7777 28.4445 11.7777C23.889 11.7777 20.6667 14.5555 20.6667 19.5555V23.9999H15.6667V29.5555H20.6667V43.9999C22.889 43.9999 25.1112 43.9999 27.3334 43.9999V29.5555H31.7779Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_149_671"
              x1="24"
              y1="42.8313"
              x2="24"
              y2="4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#0062E0" />
              <stop offset="1" stopColor="#19AFFF" />
            </linearGradient>
          </defs>
        </svg>
      </a>

      <a
        href="https://zalo.me/0945277777"
        id="w-v6ttd4ft"
        className="p-absolute cursor-pointer animation">
        <div className="image-block-css p-relative full-width full-height full-mask-size mask-position">
          <div className="image-background p-absolute"></div>
        </div>
      </a>

      <a
        href={`tel:${"0945277777"}`}
        id="w-v6ttd4ft1"
        className="p-absolute cursor-pointer animation">
        <div className="image-background p-absolute"></div>
        <h5
          style={{
            marginLeft: '-150px', color: 'black', marginTop: '10px',
            backgroundColor: 'antiquewhite', width: '92px', height: '30px',
            borderRadius: '10px', textAlign: 'center'
          }}>
          0945277777
        </h5>
      </a>
      <Modal show={show} handleClose={handleClose} handleSendEmail={handleSendEmail} bks={bks} handlePhone={handlePhone} handleToEmail={handleToEmail} handleNote={handleNote} />
      <ModalDichbien isOpen={isModalDichOpen} onClose={() => setIsModalDichOpen(false)} bks={bks} />
    </div>
  );
}

export default Home;