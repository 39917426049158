import Home from "./Home";
import DichBien from "./DichBien";
import Admin from "./Admin";
import SvgIcons from './SvgIcons';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <SvgIcons/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dich-bien" element={<DichBien />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
    </Router>
  );
}

export default App;
