import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/modal-dich-bien.css';

import { API_BASE_URL_LOCAL, API_BASE_URL_SERVER } from './contants.js';

const ModalDichbien = ({ isOpen, onClose, bks }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      // Define a function to fetch data
      const fetchData = async () => {
        try {
          setIsLoading(true); // Set loading to true before fetching data
          const response = await axios.get(`${API_BASE_URL_SERVER}bien-so/yn?bien=${bks}`);
          setData(response.data);
          console.log(response.data);
        } catch (err) {
          // Handle error if necessary
        } finally {
          setIsLoading(false); // Set loading to false after data is fetched
        }
      };
      fetchData();
    }
  }, [isOpen, bks]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        {isLoading ? (
          <p>Loading...</p> // Display a loading message while data is being fetched
        ) : (
          <>
            <h3 style={{ color: "black", textAlign: 'center' }}>
              Bộ số của biển <span className="highlight">{bks}</span> được dịch là
            </h3>
            <h2 className="title">{data.yn2}</h2>
            <ul>
              {data.yn3?.['0']?.[3] && <li dangerouslySetInnerHTML={{ __html: data.yn3?.['0']?.[3] }} />}
              {data.yn3?.['1']?.[3] && <li dangerouslySetInnerHTML={{ __html: data.yn3?.['1']?.[3] }} />}
              {data.yn3?.['2']?.[3] && <li dangerouslySetInnerHTML={{ __html: data.yn3?.['2']?.[3] }} />}
              {data.yn3?.['3']?.[3] && <li dangerouslySetInnerHTML={{ __html: data.yn3?.['3']?.[3] }} />}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalDichbien;
