import React, { useEffect, useState } from 'react';
import Flickity from 'react-flickity-component';
import axios from 'axios';
import { Routes, Route, Link } from 'react-router-dom';

import 'flickity/css/flickity.css';
import './css/flatpickr.min.css';
import './css/flatsome.css';
import './css/style-text.css';
import './css/styles.css';

import banner1 from './image/banner1.png';
import banner2 from './image/banner2.png';
import banner3 from './image/banner3.png';
import banner4 from './image/banner2.png';
import bigLogo from './image/bigLogo.png';
import call from './image/call.png';
import flogo1 from './image/flogo1.png';
import flogo3 from './image/flogo3.png';
import i3 from './image/i3.svg';
import logoNew from './image/logo-new.jpg';
import logoSaleNoti from './image/logoSaleNoti.png';
import img1 from './image/img1.jpg';
import img2 from './image/img2.jpg';
import img3 from './image/img3.jpg';
import img4 from './image/img4.jpg';
import img5 from './image/img5.jpg';
import img6 from './image/img6.jpg';
import img7 from './image/img7.jpg';
import img8 from './image/img8.jpg';

// Contants
import { API_BASE_URL_LOCAL, API_BASE_URL_SERVER } from './contants.js';


function DichBien() {
  const [bksSearch, setBksSearch] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleChangeBksSearch = (event) => {
    setIsLoading(true);
    setBksSearch(event.target.value);
  };

  const handleClickBtnSearch = () => {
    console.log('BTN SEARCH: ', bksSearch)
    callApiDichBien();
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const callApiDichBien = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL_SERVER}bien-so/yn?bien=${bksSearch}`);
      setData(response.data);
    } catch (err) {
      // Handle error if necessary
    } finally {
      setIsLoading(false);
    }
  };
  const flickityOptions = {
    cellAlign: 'center',
    imagesLoaded: true,
    lazyLoad: 1,
    freeScroll: false,
    wrapAround: true,
    autoPlay: 6000,
    pauseAutoPlayOnHover: true,
    prevNextButtons: true,
    contain: true,
    adaptiveHeight: true,
    dragThreshold: 10,
    percentPosition: true,
    pageDots: true,
    rightToLeft: false,
    draggable: true,
    selectedAttraction: 0.1,
    parallax: 0,
    friction: 0.6,
  };

  const QhLuachoncopy = () => (
    <div className="qh_luachoncopy">
      <button className="a1">Copy biển + giá</button>
      <button className="a2">Copy biển</button>
    </div>
  );

  const PopCopy = () => (
    <div className="pop_copy">Đã copy !</div>
  );

  const provinces = [
    { name: "Tỉnh Thành", matinh: "" },
    { name: "Hà Nội", matinh: "01" },
    { name: "Hà Giang", matinh: "02" },
    { name: "Cao Bằng", matinh: "04" },
    { name: "Bắc Kạn", matinh: "06" },
    { name: "Tuyên Quang", matinh: "08" },
    { name: "Lào Cai", matinh: "10" },
    { name: "Điện Biên", matinh: "11" },
    { name: "Lai Châu", matinh: "12" },
    { name: "Sơn La", matinh: "14" },
    { name: "Yên Bái", matinh: "15" },
    { name: "Hòa Bình", matinh: "17" },
    { name: "Thái Nguyên", matinh: "19" },
    { name: "Lạng Sơn", matinh: "20" },
    { name: "Quảng Ninh", matinh: "22" },
    { name: "Bắc Giang", matinh: "24" },
    { name: "Phú Thọ", matinh: "25" },
    { name: "Vĩnh Phúc", matinh: "26" },
    { name: "Bắc Ninh", matinh: "27" },
    { name: "Hải Dương", matinh: "30" },
    { name: "Hải Phòng", matinh: "31" },
    { name: "Hưng Yên", matinh: "33" },
    { name: "Thái Bình", matinh: "34" },
    { name: "Hà Nam", matinh: "35" },
    { name: "Nam Định", matinh: "36" },
    { name: "Ninh Bình", matinh: "37" },
    { name: "Thanh Hóa", matinh: "38" },
    { name: "Nghệ An", matinh: "40" },
    { name: "Hà Tĩnh", matinh: "42" },
    { name: "Quảng Bình", matinh: "44" },
    { name: "Quảng Trị", matinh: "45" },
    { name: "Thừa Thiên Huế", matinh: "46" },
    { name: "Đà Nẵng", matinh: "48" },
    { name: "Quảng Nam", matinh: "49" },
    { name: "Quảng Ngãi", matinh: "51" },
    { name: "Bình Định", matinh: "52" },
    { name: "Phú Yên", matinh: "54" },
    { name: "Khánh Hòa", matinh: "56" },
    { name: "Ninh Thuận", matinh: "58" },
    { name: "Bình Thuận", matinh: "60" },
    { name: "Kon Tum", matinh: "62" },
    { name: "Gia Lai", matinh: "64" },
    { name: "Đắk Lắk", matinh: "66" },
    { name: "Đắk Nông", matinh: "67" },
    { name: "Lâm Đồng", matinh: "68" },
    { name: "Bình Phước", matinh: "70" },
    { name: "Tây Ninh", matinh: "72" },
    { name: "Bình Dương", matinh: "74" },
    { name: "Đồng Nai", matinh: "75" },
    { name: "Bà Rịa - Vũng Tàu", matinh: "77" },
    { name: "Hồ Chí Minh", matinh: "79" },
    { name: "Long An", matinh: "80" },
    { name: "Tiền Giang", matinh: "82" },
    { name: "Bến Tre", matinh: "83" },
    { name: "Trà Vinh", matinh: "84" },
    { name: "Vĩnh Long", matinh: "86" },
    { name: "Đồng Tháp", matinh: "87" },
    { name: "An Giang", matinh: "89" },
    { name: "Kiên Giang", matinh: "91" },
    { name: "Cần Thơ", matinh: "92" },
    { name: "Hậu Giang", matinh: "93" },
    { name: "Sóc Trăng", matinh: "94" },
    { name: "Bạc Liêu", matinh: "95" },
    { name: "Cà Mau", matinh: "96" }
  ];

  const QhDangkybien = () => (
    <div className="qh_dangkybien">
      <div className="bienxe">
        <h3>Đăng ký biển</h3>
        <p></p>
      </div>
      <div className="wpcf7 no-js" id="wpcf7-f638-o1" lang="vi" dir="ltr">
        <div className="screen-reader-response">
          <p role="status" aria-live="polite" aria-atomic="true"></p>
          <ul></ul>
        </div>
        <form
          action="/bien-so-xe/?tinhthanh=49#wpcf7-f638-o1"
          method="post"
          className="wpcf7-form init"
          aria-label="Form liên hệ"
          noValidate
        >
          <div style={{ display: 'none' }}>
            <input type="hidden" name="_wpcf7" value="638" />
            <input type="hidden" name="_wpcf7_version" value="5.8.3" />
            <input type="hidden" name="_wpcf7_locale" value="vi" />
            <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f638-o1" />
            <input type="hidden" name="_wpcf7_container_post" value="0" />
            <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
          </div>
          <div style={{ display: 'none' }}>
            <p>
              <span className="wpcf7-form-control-wrap" data-name="qhvn">
                <input
                  size="40"
                  className="wpcf7-form-control wpcf7-text"
                  aria-invalid="false"
                  value=""
                  type="text"
                  name="qhvn"
                />
              </span>
            </p>
          </div>
          <p>
            <span className="wpcf7-form-control-wrap" data-name="your-name">
              <input
                size="40"
                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                aria-required="true"
                aria-invalid="false"
                placeholder="Họ và tên"
                value=""
                type="text"
                name="your-name"
              />
            </span>
            <span className="wpcf7-form-control-wrap" data-name="your-phone">
              <input
                size="40"
                className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel"
                aria-required="true"
                aria-invalid="false"
                placeholder="Số điện thoại"
                value=""
                type="tel"
                name="your-phone"
              />
            </span>
            <span className="wpcf7-form-control-wrap" data-name="your-bien">
              <input
                size="40"
                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                aria-required="true"
                aria-invalid="false"
                value=""
                type="text"
                name="your-bien"
              />
            </span>
            <span className="wpcf7-form-control-wrap" data-name="loaibien">
              <input
                size="40"
                className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                aria-required="true"
                aria-invalid="false"
                value=""
                type="text"
                name="loaibien"
              />
            </span>
            <input
              className="wpcf7-form-control wpcf7-submit has-spinner"
              type="submit"
              value="Đăng ký"
            />
          </p>
          <div className="wpcf7-response-output" aria-hidden="true"></div>
        </form>
      </div>
    </div>
  );

  const QhDinhgia = () => (
    <div className="qh_dinhgia">
      <span>x</span>
      <h3>ĐỊNH GIÁ BIỂN SỐ XE</h3>
      <p>
        <strong>biensolocphat.vn</strong> mang đến cho bạn kho dữ liệu biển số xe phục vụ việc chia sẻ thông tin, phân
        tích ý nghĩa biển số xe của bạn đang sử dụng một cách chính xác.
      </p>
      <div>
        <input className="" type="text" placeholder="Nhập biển số xe 5 số" />
        <button>Định giá</button>
      </div>
      <p>Gợi ý: 45678 , 66886, 19179, 7979…</p>
    </div>
  );

  const Overlay = ({ className }) => (
    <div className={className}></div>
  );

  return (
    <div className="page-template page-template-page-blank page-template-page-blank-php page page-id-635 lightbox nav-dropdown-has-arrow nav-dropdown-has-shadow nav-dropdown-has-border">
      <div id="wrapper">
        <header className="header has-sticky sticky-jump">
          <div className="header-wrapper">
            <div id="masthead" className="header-main nav-dark">
              <div className="header-inner flex-row container logo-left medium-logo-center" role="navigation">
                {/* Logo */}
                <div id="logo" className="flex-col logo">
                  {/* Header logo */}
                  <a href="" title="Biển số đẹp" rel="home">
                    {/* <img width="857" height="1024" src={logoNew} className="header_logo header-logo" alt="Biển số đẹp" />
                    <img width="857" height="1024" src={logoNew} className="header-logo-dark" alt="Biển số đẹp" /> */}

                    <img
                      style={{ filter: 'none' }}
                      className="alignnone wp-image-2666"
                      src={logoNew}
                      alt=""
                      width="400"
                      height="80"
                    />

                  </a>
                </div>
                {/* Mobile Left Elements */}
                <div>
                  <div className="flex-col show-for-medium flex-left">
                    <ul className="mobile-nav nav nav-left">
                      <li className="nav-icon has-icon">
                        <a
                          href="#"
                          onClick={toggleMenu}
                          className="is-small"
                          aria-label="Menu"
                          aria-controls="main-menu"
                          aria-expanded={isMenuOpen}
                        >
                          <i className="icon-menu"></i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className={`slide-menu ${isMenuOpen ? 'open' : ''}`}>
                    <div className="menu-content">
                    <ul>
                        <li>
                          <Link to="/" className="nav-top-link">Trang chủ</Link>
                        </li>
                        <li>
                          <Link to="/dich-bien" className="nav-top-link">Dịch biển xe</Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* Optional: Overlay to close the menu when clicking outside */}
                  {isMenuOpen && <div className="menu-overlay" onClick={toggleMenu}></div>}
                </div>
                {/* Left Elements */}
                <div className="flex-col hide-for-medium flex-left flex-grow">
                  <ul className="header-nav header-nav-main nav nav-left nav-uppercase">
                    <li id="menu-item-639" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-635 current_page_item menu-item-639 active menu-item-design-default">
                      <a href="/" aria-current="page" className="nav-top-link">Trang chủ</a>
                    </li>
                    <li id="menu-item-639" className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-635 current_page_item menu-item-639 active menu-item-design-default">
                      <a href="/" className="nav-top-link">Dịch biển xe</a>
                    </li>
                    {/* <li id="menu-item-2180" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2180 menu-item-design-default">
                      <a href="/" className="nav-top-link">Tìm biển theo yêu cầu</a>
                    </li> */}
                  </ul>
                </div>
                {/* Right Elements */}
                <div className="flex-col hide-for-medium flex-right">
                  <ul className="header-nav header-nav-main nav nav-right nav-uppercase">
                    <li className="html custom html_topbar_left">
                      <a href="tel:0923467777" className="qh_a">
                        <i><img src={call} alt="icon" /></i>
                        <span>09452.77777</span>
                        <span>Gọi để được hỗ trợ</span>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* Mobile Right Elements */}
                <div className="flex-col show-for-medium flex-right">
                  <ul className="mobile-nav nav nav-right"></ul>
                </div>
              </div>
              <div className="container">
                <div className="top-divider full-width"></div>
              </div>
            </div>
            <div className="header-bg-container fill">
              <div className="header-bg-image fill"></div>
              <div className="header-bg-color fill"></div>
            </div>
          </div>
        </header>

        <div className="slider-wrapper relative qh_slide" id="slider-1663031332">
          <Flickity className="slider slider-nav-circle slider-nav-large slider-nav-light slider-style-normal" options={flickityOptions}>
            <div className="img has-hover x md-x lg-x y md-y lg-y" id="image_168604042">
              <div className="img-inner dark">
                <img width="1825" height="700" src={banner1} sizes="(max-width: 1825px) 100vw, 1825px" alt="Banner 1" />
              </div>
            </div>

            <div className="img has-hover x md-x lg-x y md-y lg-y" id="image_1296941712">
              <div className="img-inner dark">
                <img width="2560" height="905" src={banner2} sizes="(max-width: 2560px) 100vw, 2560px" alt="Banner 2" />
              </div>
            </div>

            <div className="img has-hover x md-x lg-x y md-y lg-y" id="image_1052597072">
              <div className="img-inner dark">
                <img width="2560" height="905" src={banner3} sizes="(max-width: 2560px) 100vw, 2560px" alt="Banner 3" />
              </div>
            </div>

            <div className="img has-hover x md-x lg-x y md-y lg-y" id="image_640986173">
              <div className="img-inner dark">
                <img width="2048" height="723" src={banner4} sizes="(max-width: 2048px) 100vw, 2048px" alt="Banner 4" />
              </div>
            </div>

          </Flickity>
        </div>

        <main id="main" className="dark dark-page-wrapper">
          <div id="content" role="main" className="content-area">
            <section className="section dark" id="section_710753446">
              <div className="bg section-bg fill bg-fill bg-loaded back-ground-co"></div>
              <div className="section-content relative">
                <div class="row row-small row-full-width" id="row-652602724">
                  <div id="col-1854014999" className="col hide-for-medium medium-2 small-12 large-2"></div>
                  {/* CONTENT */}
                  <div id="col-637516760" className="col medium-12 small-12 large-8">
                    <h1 style={{ marginTop: '3%', marginBottom: '3%' }}>Dịch biển số</h1>
                    <div className="a c1" style={{ display: 'flex' }}>
                      <input
                        type="text"
                        placeholder="Nhập tìm kiếm biển số xe"
                        autoComplete="off"
                        value={bksSearch}
                        onChange={handleChangeBksSearch}
                      />
                      <button onClick={handleClickBtnSearch} style={{ backgroundColor: 'red', width: '15%' }}>
                        <img decoding="async" src={i3} alt="input" />
                      </button>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <h5 className="text-left special-content_title d-block w-100 pb-2 mb-2 font-weight-bold text-main">
                        Ý NGHĨA BIỂN SỐ </h5>
                      {isLoading ? (
                        <p>Loading...</p> // Display a loading message while data is being fetched
                      ) : (

                        <div className="row">
                          <div className="col-xl-9 col-lg-8 col-12 pr-lg-0">
                            <div className="mg_out">
                              <div className="mew_product_tab tab_link active loaded swiper-container position-static p-2 pb-3 pb-lg-2 swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events swiper-container-free-mode swiper-container-multirow" data-pos-content={0}>
                                <div>
                                  <p>- Bộ số của biển {bksSearch} được dịch là: {data.yn2}</p>
                                  {data.yn3?.['0']?.[3] && (
                                    <p style={{ display: 'flex', marginBottom: '8px' }}>
                                      <span style={{ marginRight: '3px' }}>- </span>
                                      <span className='span-dich' dangerouslySetInnerHTML={{ __html: data.yn3?.['0']?.[3] }} />
                                    </p>
                                  )}
                                  {/* {data.yn3?.['1']?.[3] && <p style={{ marginLeft: 0 }} dangerouslySetInnerHTML={{ __html: data.yn3?.['1']?.[3] }} />} */}
                                  {data.yn3?.['1']?.[3] && (
                                    <p style={{ display: 'flex', marginBottom: '8px'  }}>
                                      <span style={{ marginRight: '3px' }}>- </span>
                                      <span className='span-dich' dangerouslySetInnerHTML={{ __html: data.yn3?.['1']?.[3] }} />
                                    </p>
                                  )}
                                  {/* {data.yn3?.['2']?.[3] && <p style={{ marginLeft: 0 }} dangerouslySetInnerHTML={{ __html: data.yn3?.['2']?.[3] }} />} */}
                                  {data.yn3?.['2']?.[3] && (
                                    <p style={{ display: 'flex', marginBottom: '8px'  }}>
                                      <span style={{ marginRight: '3px' }}>- </span>
                                      <span className='span-dich' dangerouslySetInnerHTML={{ __html: data.yn3?.['2']?.[3] }} />
                                    </p>
                                  )}
                                  {/* {data.yn3?.['3']?.[3] && <p style={{ marginLeft: 0 }} dangerouslySetInnerHTML={{ __html: data.yn3?.['3']?.[3] }} />} */}
                                  {data.yn3?.['3']?.[3] && (
                                    <p style={{ display: 'flex', marginBottom: '8px'  }}>
                                      <span style={{ marginRight: '3px' }}>- </span>
                                      <span className='span-dich' dangerouslySetInnerHTML={{ __html: data.yn3?.['3']?.[3] }} />
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-4 col-12">
                            <div className="b_product p-2 pl-lg-0 d-flex flex-lg-column">
                              <a
                                href="https://khobiensodep.vn/collections/bien-so-ngu-quy"
                                title="Banner"
                                className="rounded-10 modal-open position-relative w-100 w-mb-50 ratio1by1 has-edge aspect d-block sitdown"
                              >
                                <img
                                  className="d-block img img-cover position-absolute lazy loaded"
                                  src={img6}
                                  alt="Banner"
                                />
                              </a>
                            </div>
                          </div>
                        </div>

                      )}
                    </div>
                  </div>
                  {/* CONTENT */}
                </div>
              </div>
            </section>
          </div>
        </main>
        <footer id="footer" className="footer-wrapper">
          <section className="section qh_f1 dark" id="section_1815429417">
            <div className="bg section-bg fill bg-fill bg-loaded"></div>
            <div className="section-content relative">
              <div className="row row-small" id="row-201707142">
                <div id="col-1578809429" className="col small-12 large-12">
                  <div className="col-inner">
                    <h2>
                      <img
                        className="alignnone wp-image-2666"
                        src={logoNew}
                        alt=""
                        width="400"
                        height="80"
                      />
                    </h2>
                    <h2>Công TY Biển Số Đẹp Việt Nam</h2>
                    <h4>Chi nhánh tại các Thành Phố lớn :</h4>
                    <p>
                      HÀ NỘI: L7--66 Khu Đô Thị Athena Fullland Đại Kim, Hoàng Mai, Hà Nội.
                    </p>
                    <p>
                      TP HCM: 430 Lý Thái Tổ, Q10 , Tp HCM.
                    </p>
                    <p>
                      ĐÀ NẴNG: 75 Nguyễn Tri Phương, Thạc Gián, Q. Thanh Khê, Đà Nẵng.
                    </p>
                    <p>
                      NHA TRANG: 233 Nguyễn Thị Minh Khai, Phước Hoà, Nha Trang.
                    </p>
                    <p>
                      CẦN THƠ: 74 Xô Viết Nghệ Tĩnh, An Cư, Ninh Kiều, Cần Thơ.
                    </p>
                    <p>
                      <img
                        className="alignnone wp-image-61"
                        src={logoSaleNoti}
                        alt=""
                        width="200"
                        height="76"
                      />
                      <img
                        className="wp-image-239"
                        style={{ marginLeft: '20px' }}
                        src={flogo3}
                        alt=""
                        width="133"
                        height="40"
                      />
                      <img
                        className="wp-image-240"
                        style={{ marginLeft: '20px' }}
                        src={flogo1}
                        alt=""
                        width="65"
                        height="39"
                      />
                      <img
                        className="alignnone wp-image-567"
                        style={{ marginLeft: '20px' }}
                        src={logoNew}
                        alt=""
                        width="50"
                        height="60"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="absolute-footer dark medium-text-center small-text-center">
            <div className="container clearfix">
              <div className="footer-primary pull-left">
                <div className="copyright-footer"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <>
        <QhLuachoncopy />
        <Overlay className="ovl_luachoncopy" />
        <PopCopy />
        <QhDangkybien />
        <Overlay className="ovl_dangkybien" />
        <QhDinhgia />
        <Overlay className="ovl_dinhgia" />
      </>
    </div>
  );
}

export default DichBien;